import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
	...SectionSplitProps.types
}

const defaultProps = {
	...SectionSplitProps.defaults
}

const FeaturesSplit = ({
						   className,
						   topOuterDivider,
						   bottomOuterDivider,
						   topDivider,
						   bottomDivider,
						   hasBgColor,
						   invertColor,
						   invertMobile,
						   invertDesktop,
						   alignTop,
						   imageFill,
						   ...props
					   }) => {

	const outerClasses = classNames(
		'features-split section',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-split-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const splitClasses = classNames(
		'split-wrap',
		invertMobile && 'invert-mobile',
		invertDesktop && 'invert-desktop',
		alignTop && 'align-top'
	);

	const sectionHeader = {
		title: '我们的优势',
		paragraph: ''
	};

	return (
		<section
			{...props}
			className={outerClasses}
		>
			<div className="container">
				<div className={innerClasses}>
					<SectionHeader data={sectionHeader} className="center-content"/>
					<div className={splitClasses}>
						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-right"
								 data-reveal-container=".split-item">
								<div className="text-xxs text-color-primary fw-600 tt-u mb-8">
									User-friendly interface
								</div>
								<h3 className="mt-0 mb-12">
									易操作的用户界面
								</h3>
								<ul className="m-0">
									<li>快速易上手的管理页面</li>
									<li>全面而细致的地区分类</li>
									<li>个性化Proxies生成方式，支持多产品混合生成</li>
									<li>独有的生成方案保存功能，一键生成Proxies</li>
									<li>接受多种支付方式：微信/支付宝/信用卡</li>
								</ul>
							</div>
							<div className={
								classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill'
								)}
								 data-reveal-container=".split-item">
								<Image
									src={require('./../../assets/images/user_interface_bg.svg')}
									alt="Features split 02"
									width={528}
									height={396}/>
							</div>
						</div>

						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-left"
								 data-reveal-container=".split-item">
								<div className="text-xxs text-color-primary fw-600 tt-u mb-8">
									Exclusive online service
								</div>
								<h3 className="mt-0 mb-12">
									专人在线服务
								</h3>
								<ul className="m-0">
									<li>24小时支持的工单系统</li>
									<li>专人回复，专业高效</li>
									<li>让您的每一个问题都得到妥善处理</li>
								</ul>
							</div>
							<div className={
								classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill'
								)}
								 data-reveal-container=".split-item">
								<Image
									src={require('./../../assets/images/online_service_bg.svg')}
									alt="Features split 03"
									width={400}
									height={396}/>
							</div>
						</div>

					</div>
				</div>
			</div>
		</section>
	);
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;