import React from 'react';
import classNames from 'classnames';
import {SectionTilesProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
	...SectionTilesProps.types
}

const defaultProps = {
	...SectionTilesProps.defaults
}
const FeaturesTiles = ({
						   className,
						   topOuterDivider,
						   bottomOuterDivider,
						   topDivider,
						   bottomDivider,
						   hasBgColor,
						   invertColor,
						   pushLeft,
						   ...props
					   }) => {

	const outerClasses = classNames(
		'features-tiles section',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-tiles-inner section-inner pt-0',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const tilesClasses = classNames(
		'tiles-wrap center-content',
		pushLeft && 'push-left'
	);

	const sectionHeader = {
		title: '为什么选择我们',
		paragraph: ''
	};

	return (
		<section
			{...props}
			className={outerClasses}
		>
			<div className="container">
				<div className={innerClasses}>
					<SectionHeader data={sectionHeader} className="center-content"/>
					<div className={tilesClasses}>

						<div className="tiles-item reveal-from-bottom">
							<div className="tiles-item-inner">
								<div className="features-tiles-item-header">
									<div className="features-tiles-item-image mb-16">
										<Image
											src={require('./../../assets/images/feature-tile-icon-01.svg')}
											alt="Features tile icon 01"
											width={64}
											height={64}/>
									</div>
								</div>
								<div className="features-tiles-item-content">
									<h4 className="mt-0 mb-8">
										产品多元
									</h4>
									<p className="m-0 text-sm">
										集多元化的优质住宅代理供应商于一体，多样化的产品组合，具有竞争力的价格
									</p>
								</div>
							</div>
						</div>

						<div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
							<div className="tiles-item-inner">
								<div className="features-tiles-item-header">
									<div className="features-tiles-item-image mb-16">
										<Image
											src={require('./../../assets/images/feature-tile-icon-02.svg')}
											alt="Features tile icon 02"
											width={64}
											height={64}/>
									</div>
								</div>
								<div className="features-tiles-item-content">
									<h4 className="mt-0 mb-8">
										覆盖范围广
									</h4>
									<p className="m-0 text-sm">
										提供超过220个国家和地区1.92亿个IP
									</p>
								</div>
							</div>
						</div>

						<div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
							<div className="tiles-item-inner">
								<div className="features-tiles-item-header">
									<div className="features-tiles-item-image mb-16">
										<Image
											src={require('./../../assets/images/feature-tile-icon-03.svg')}
											alt="Features tile icon 03"
											width={64}
											height={64}/>
									</div>
								</div>
								<div className="features-tiles-item-content">
									<h4 className="mt-0 mb-8">
										极致性能
									</h4>
									<p className="m-0 text-sm">
										稳定而优异的性能，高达98.2%的响应率
									</p>
								</div>
							</div>
						</div>

						<div className="tiles-item reveal-from-bottom">
							<div className="tiles-item-inner">
								<div className="features-tiles-item-header">
									<div className="features-tiles-item-image mb-16">
										<Image
											src={require('./../../assets/images/feature-tile-icon-04.svg')}
											alt="Features tile icon 04"
											width={64}
											height={64}/>
									</div>
								</div>
								<div className="features-tiles-item-content">
									<h4 className="mt-0 mb-8">
										流量锁定
									</h4>
									<p className="m-0 text-sm">
										独有的流量锁定功能，用至限额自动断网，24h守护你的流量
									</p>
								</div>
							</div>
						</div>

						<div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
							<div className="tiles-item-inner">
								<div className="features-tiles-item-header">
									<div className="features-tiles-item-image mb-16">
										<Image
											src={require('./../../assets/images/feature-tile-icon-05.svg')}
											alt="Features tile icon 05"
											width={64}
											height={64}/>
									</div>
								</div>
								<div className="features-tiles-item-content">
									<h4 className="mt-0 mb-8">
										高效便捷
									</h4>
									<p className="m-0 text-sm">
										随心所欲而高效的生成你需要的IP
									</p>
								</div>
							</div>
						</div>

						<div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
							<div className="tiles-item-inner">
								<div className="features-tiles-item-header">
									<div className="features-tiles-item-image mb-16">
										<Image
											src={require('./../../assets/images/feature-tile-icon-06.svg')}
											alt="Features tile icon 06"
											width={64}
											height={64}/>
									</div>
								</div>
								<div className="features-tiles-item-content">
									<h4 className="mt-0 mb-8">
										用户反馈
									</h4>
									<p className="m-0 text-sm">
										IP通过率高，被禁用率低，95%的用户都说好
									</p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</section>
	);
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;